import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  copy(e) {
    e.preventDefault();

    const text = this.element.dataset.text;
    navigator.clipboard.writeText(text)
      .then(() => {
        toastr.success(`${text} copied to clipboard!`);
      })
      .catch(err => {
        console.error(err);
        toastr.error("Failed to copy.");
      });
  }
}